import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase'; // Firebase Firestore setup
import { collection, query, where, getDocs } from 'firebase/firestore'; // Correct functions for querying by slug
import { Helmet } from 'react-helmet'; // For SEO
import GlobalStyle from '../GlobalStyles';
import Navbar from './Navbar';

// Styled components for blog post
const BlogContainer = styled.div`
  padding: 50px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const BlogTitle = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
  object-fit: cover;
`;

const BlogContent = styled.div`
  font-size: 18px;
  line-height: 1.7;
  color: #333;

  h2 {
    font-size: 28px;
    color: #4CAF50;
    margin-top: 40px;
  }

  h3 {
    font-size: 24px;
    margin-top: 20px;
    color: #333;
  }

  p {
    margin: 20px 0;
    line-height: 1.8;
  }

  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 8px;
  }

  blockquote {
    font-style: italic;
    background-color: #f9f9f9;
    padding: 20px;
    margin: 20px 0;
    border-left: 4px solid #4CAF50;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  padding: 20px;
  background: linear-gradient(135deg, #1a73e8, #4caf50);
  border-radius: 8px;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const CTAButton = styled.button`
  background-color: #000;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const BlogPost = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch blog post from Firestore based on the slug
  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Query Firestore for the blog post with the matching slug
        const blogQuery = query(collection(db, 'blogs'), where('slug', '==', slug));
        const querySnapshot = await getDocs(blogQuery);

        if (!querySnapshot.empty) {
          const blogPost = querySnapshot.docs[0].data();
          setPost(blogPost);
        } else {
          console.log('No such document with this slug!');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching the blog post:', error);
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (!post) {
    return <h1>Post not found</h1>;
  }

  return (
    <>
      <GlobalStyle />
      <Navbar />
      <BlogContainer>
        {/* SEO Optimization */}
        <Helmet>
          <title>{post.title} | GradJump Blog</title>
          <meta name="description" content={post.content.slice(0, 150)} />
          <meta name="keywords" content="startup, entrepreneurship, graduates, team collaboration" />
        </Helmet>

        <BlogTitle>{post.title}</BlogTitle>
        {post.image && <BlogImage src={post.image} alt={post.title} />}
        {/* CTA at the top */}
        <CTAContainer>
          <span>Join a startup team now from the ground up!</span>
          <CTAButton onClick={() => navigate('/apply')}>Apply Now</CTAButton>
        </CTAContainer>
        {/* Render Blog Content with rich HTML */}
        <BlogContent dangerouslySetInnerHTML={{ __html: post.content }} />
        {/* CTA at the bottom */}
        <CTAContainer>
          <span>Build experience, push boundaries, and make money!</span>
          <CTAButton onClick={() => navigate('/apply')}>Apply Now</CTAButton>
        </CTAContainer>
      </BlogContainer>
    </>
  );
};

export default BlogPost;
