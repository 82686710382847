// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";  // Import Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB9FRrV9iqeO1sSCAl-WRof7FpM1VNC0JI",
  authDomain: "inniva-460ea.firebaseapp.com",
  projectId: "inniva-460ea",
  storageBucket: "inniva-460ea.appspot.com",
  messagingSenderId: "1070359233634",
  appId: "1:1070359233634:web:74e1dbbc2c1a160c46fd3b",
  measurementId: "G-FDH16R6DFB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Optional: Initialize Analytics if you're using it
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication, Firestore, and Storage
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);  // Export Firebase Storage
