import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { collection, getDocs } from 'firebase/firestore'; // For fetching blog posts from Firestore
import { db } from '../firebase'; // Assuming Firebase is set up in firebase.js
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import GlobalStyle from '../GlobalStyles'; 
import Navbar from '../components/Navbar';
import BlogList from '../components/BlogList'; // Component to show the list of blogs
import BlogPost from '../components/BlogPost'; // Component to show an individual blog post

// Styled Components
const BlogScreenContainer = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.h1`
  font-size: 48px;
  margin-bottom: 40px;
  color: #333;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const PaginationContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .pagination {
    display: flex;
    list-style: none;
    gap: 10px;
  }

  .pagination li {
    cursor: pointer;
    padding: 10px 15px;
    background: #333;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .pagination li:hover {
    background-color: #4CAF50;
  }

  .pagination .selected {
    background-color: #4CAF50;
  }
`;

const NoPostsMessage = styled.p`
  font-size: 24px;
  color: #666;
  margin-top: 50px;
`;

// Main BlogScreen Component
const BlogScreen = () => {
  const { slug } = useParams(); // Get the slug from URL if present
  const navigate = useNavigate();
  
  const [posts, setPosts] = useState([]); // To store blog posts
  const [loading, setLoading] = useState(true); // To track loading state
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage] = useState(5); // Show 5 posts per page
  
  // Fetch blog posts from Firestore when the component mounts
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogs'));
        const blogPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched Blog Posts:", blogPosts); // Debugging log
        setPosts(blogPosts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const pageCount = Math.ceil(posts.length / postsPerPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    window.scrollTo(0, 0); // Scroll to top on page change
  };

  const startIndex = currentPage * postsPerPage;
  const selectedPosts = posts.slice(startIndex, startIndex + postsPerPage);

  useEffect(() => {
    if (!slug) {
      // Scroll to top when blog list is viewed
      window.scrollTo(0, 0);
    }
  }, [slug]);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <GlobalStyle />
      <Navbar />
      <BlogScreenContainer>
        {slug ? (
          <>
            <Helmet>
              <title>{`${posts.find((post) => post.slug === slug)?.title} | GradJump Blog`}</title>
              <meta name="description" content="Read the latest GradJump blog post" />
              <meta name="keywords" content="startup, graduate, team collaboration, entrepreneurship, tech blogs" />
            </Helmet>
            <BlogPost posts={posts} />
          </>
        ) : (
          <>
            <Helmet>
              <title>Blog | GradJump</title>
              <meta name="description" content="Explore the latest posts on GradJump, focusing on startups, entrepreneurship, and team collaboration." />
              <meta name="keywords" content="startups, graduates, entrepreneurship, team building, projects, innovation" />
            </Helmet>
            <Header>Explore Our Blog</Header>
            {posts.length === 0 ? (
              <NoPostsMessage>No blog posts available at the moment. Check back soon!</NoPostsMessage>
            ) : (
              <>
                <BlogList posts={selectedPosts} />
                <PaginationContainer>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"selected"}
                  />
                </PaginationContainer>
              </>
            )}
          </>
        )}
      </BlogScreenContainer>
    </>
  );
};

export default BlogScreen;
