import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { FaHome, FaUsers, FaBars, FaTimes, FaSignOutAlt, FaClipboardList } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { signOut } from 'firebase/auth'; // Import Firebase signOut function
import { auth } from '../firebase'; // Import the auth instance
import GlobalStyle from '../GlobalStyles'; 

// Styled components
const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Logo = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: #4CAF50;
  cursor: pointer;
`;

const NavIcon = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: block;
    font-size: 24px;
    cursor: pointer;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
    text-align: center;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: color 0.3s ease;

  &:hover {
    color: #4CAF50;
  }

  @media (max-width: 768px) {
    margin: 15px 0;
    font-size: 16px;
    margin-left: 0;
  }
`;

const NavIconLink = styled.span`
  margin-right: 8px;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #4CAF50;
  }

  @media (max-width: 768px) {
    margin: 15px 0;
    font-size: 16px;
    margin-left: 0;
  }
`;

// Navbar Component
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser } = useContext(AuthContext); // Get the current user from context
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    try {
      await signOut(auth); // Log out the user
      navigate('/login');  // Redirect to login page after logging out
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <>
      <GlobalStyle />
      <NavbarContainer>
        <Logo onClick={() => navigate('/')}>Grad Jump</Logo> {/* Redirect to Home */}
        <NavIcon onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </NavIcon>
        <NavLinks isOpen={isOpen}>
          <NavLink to="/"> {/* Home Screen */}
            <NavIconLink><FaHome /></NavIconLink>
            Home
          </NavLink>
          <NavLink to="/blogs"> {/* Blog List for all users */}
            <NavIconLink><FaClipboardList /></NavIconLink>
            Blogs
          </NavLink>
          {currentUser ? (  // Only show when user is logged in
            <>
              <NavLink to="/teams"> {/* Teams for all logged-in users */}
                <NavIconLink><FaUsers /></NavIconLink>
                Teams
              </NavLink>
              {currentUser.role === 'Admin' && (  // Only for Admins
                <>
                  <NavLink to="/applicationsdashboard"> {/* Applications Dashboard for Admin */}
                    <NavIconLink><FaUsers /></NavIconLink>
                    Applications
                  </NavLink>
                  <NavLink to="/blog-dashboard"> {/* Blog Dashboard for Admin */}
                    <NavIconLink><FaClipboardList /></NavIconLink>
                    Blog Dashboard
                  </NavLink>
                </>
              )}
              <LogoutButton onClick={handleLogout}> {/* Logout Button */}
                <NavIconLink><FaSignOutAlt /></NavIconLink>
                Logout
              </LogoutButton>
            </>
          ) : (  // For users who are not logged in
            <>
              <NavLink to="/apply"> {/* Apply Now Screen */}
                <NavIconLink><FaUsers /></NavIconLink>
                Apply Now
              </NavLink>
              <NavLink to="/login"> {/* Login Screen */}
                <NavIconLink><FaUsers /></NavIconLink>
                Login
              </NavLink>
            </>
          )}
        </NavLinks>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
