import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* Global CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* Box-sizing inheritance */
*, *::before, *::after {
  box-sizing: inherit;
}

/* Set box-sizing to border-box for all elements */
html {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 700; /* Make the text bold */
  color: #333;
  background-color: #f7f7f7;
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: 700; /* Make headings bold */
}

input, button, textarea {
  font-family: 'Lato', sans-serif;
  font-weight: 700; /* Make inputs and buttons text bold */
}

/* Ensuring images and videos scale properly */
img, video {
  max-width: 100%;
  height: auto;
}

/* Adding a responsive container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
`;

export default GlobalStyle;
