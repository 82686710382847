import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { db } from '../firebase'; // Ensure Firebase Firestore is correctly configured
import { collection, getDocs } from 'firebase/firestore';
import { Helmet } from 'react-helmet'; // For SEO

// Styled components for the Blog List
const BlogListContainer = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const BlogItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const BlogDate = styled.p`
  font-size: 14px;
  color: #888;
  margin: 10px 0;
`;

const BlogTitle = styled.h2`
  font-size: 22px;
  color: #333;
  font-weight: bold;
  margin: 10px 0;
  transition: color 0.3s ease;

  &:hover {
    color: #4CAF50;
  }
`;

const BlogExcerpt = styled.p`
  font-size: 16px;
  color: #666;
  padding: 0 20px;
`;

const BlogLink = styled(Link)`
  text-decoration: none;
  color: #4CAF50;
  font-weight: bold;
  display: inline-block;
  margin: 20px 0;
  
  &:hover {
    text-decoration: underline;
  }
`;

const BlogList = () => {
  const [posts, setPosts] = useState([]); // State to store blog posts
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetching blog posts from Firestore
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'blogs'));
        const blogPosts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title,
          slug: doc.data().slug,
          image: doc.data().image,
          content: doc.data().content,
          createdAt: doc.data().createdAt
        }));

        setPosts(blogPosts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Failed to load blog posts. Please try again later.');
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  // Handling loading state
  if (loading) {
    return <h1>Loading...</h1>;
  }

  // Handling error state
  if (error) {
    return <h1>{error}</h1>;
  }

  return (
    <>
      {/* SEO Optimization for the Blog List Page */}
      <Helmet>
        <title>Blog | GradJump</title>
        <meta
          name="description"
          content="Explore the latest posts on GradJump, focusing on startups, entrepreneurship, and team collaboration."
        />
        <meta
          name="keywords"
          content="startups, graduates, entrepreneurship, team building, projects, innovation"
        />
      </Helmet>

      <BlogListContainer>
        <h1>Latest Blog Posts</h1>
        {/* Handle case when no posts are available */}
        {posts.length === 0 ? (
          <p>No blog posts available at the moment. Check back soon!</p>
        ) : (
          <BlogGrid>
            {posts.map((post) => (
              <BlogItem key={post.slug}>
                <BlogImage src={post.image} alt={post.title} />
                <BlogDate>{new Date(post.createdAt.seconds * 1000).toLocaleDateString()}</BlogDate>
                <BlogTitle>{post.title}</BlogTitle>
                <BlogExcerpt dangerouslySetInnerHTML={{ __html: post.content.slice(0, 150) + '...' }} />
                <BlogLink to={`/blogs/${post.slug}`}>Read More</BlogLink>
              </BlogItem>
            ))}
          </BlogGrid>
        )}
      </BlogListContainer>
    </>
  );
};

export default BlogList;
