import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-modal'; // Import React Modal
import { doc, getDoc, collection, addDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage utilities
import { db, storage } from '../firebase'; // Ensure storage is imported
import { AuthContext } from '../context/AuthContext';
import GlobalStyle from '../GlobalStyles';
import { FaGithub } from 'react-icons/fa';

// Styled Components Definitions

const Container = styled.div`
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Section = styled.div`
  background-color: #f7f7f7;
  padding: 50px 5%;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  
  @media (max-width: 768px) {
    padding: 30px 20px;
    text-align: center;
  }
`;

const Header = styled.h1`
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const SubHeader = styled.h2`
  font-size: 28px;
  color: #4CAF50;
  margin-bottom: 15px;
`;

const Text = styled.p`
  font-size: 18px;
  color: #666;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TeamList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TeamMember = styled.li`
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const MemberName = styled.span`
  font-size: 18px;
  color: #333;
  font-weight: bold;
`;

const MemberRole = styled.span`
  font-size: 16px;
  color: #666;
  font-style: italic;
`;

const ChatContainer = styled.div`
  background-color: #e8f5e9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const ChatMessage = styled.div`
  margin-bottom: 15px;
`;

const ChatInput = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
`;

const TaskCategory = styled.div`
  margin-bottom: 40px;
`;

const TaskCategoryHeader = styled.h3`
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
`;

const TaskList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
`;

const TaskItem = styled.li`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskDate = styled.span`
  font-size: 14px;
  color: #888;
`;

const TaskLink = styled.a`
  color: #4CAF50;
  margin-left: 10px;
  text-decoration: underline;
`;

const TaskActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Notification = styled.div`
  background-color: #ffeb3b;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

const VibrantContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #2AF598 0%, #009EFD 100%);
  color: white;
  text-align: center;
  animation: ${pulse} 5s linear infinite;
`;

const VibrantText = styled.h1`
  font-size: 48px;
  font-weight: bold;
  margin: 20px 0;
`;

const VibrantSubText = styled.h3`
  font-size: 24px;
  margin-bottom: 40px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const SubLabel = styled.h3`
  font-size: 16px;
  color: #4CAF50; /* Professional green color to match the app branding */
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase; /* Uppercase for a clean, modern look */
  letter-spacing: 1px; /* Slight letter-spacing for readability */
`;

const ModalInput = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
`;



const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
// Styled Components for the GitHub Section
const GithubSection = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const GithubLabel = styled.h3`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const GithubInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
`;

const GithubButton = styled.button`
  background-color: #24292e; /* GitHub's button color */
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;

  &:hover {
    background-color: #444d56;
  }
`;

const GithubLink = styled.a`
  background-color: #24292e;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #444d56;
  }

  &:visited {
    color: white;
  }
`;

const TeamsScreen = () => {
  const [teamData, setTeamData] = useState(null);
  const { currentUser } = useContext(AuthContext);

  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [softwareTasks, setSoftwareTasks] = useState([]);
  const [businessTasks, setBusinessTasks] = useState([]);
  const [newSoftwareTask, setNewSoftwareTask] = useState('');
  const [newSoftwareTaskLink, setNewSoftwareTaskLink] = useState('');
  const [newBusinessTask, setNewBusinessTask] = useState('');
  const [newBusinessTaskLink, setNewBusinessTaskLink] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [currentTaskType, setCurrentTaskType] = useState('');
  const [completionLink, setCompletionLink] = useState('');
  const [file, setFile] = useState(null);
  const [notifications, setNotifications] = useState([]); // State to store notifications
  const [githubLink, setGithubLink] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const fetchTeamData = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.teamId) {
              const teamDoc = await getDoc(doc(db, 'teams', userData.teamId));
              if (teamDoc.exists()) {
                const team = teamDoc.data();

                // Fetch member details
                const memberDetails = await Promise.all(
                  team.members.map(async (memberId) => {
                    const memberDocument = await getDoc(doc(db, 'users', memberId));
                    return memberDocument.exists() ? memberDocument.data() : null;
                  })
                );

                team.members = memberDetails.filter(member => member !== null);
                setTeamData({ ...team, teamId: userData.teamId });
                setGithubLink(team.githubLink || '');
                // Fetch chat messages with user names
                const messagesRef = collection(db, 'teams', userData.teamId, 'messages');
                const unsubscribe = onSnapshot(messagesRef, async (snapshot) => {
                  const messages = await Promise.all(
                    snapshot.docs.map(async (messageDoc) => {
                      const messageData = messageDoc.data();
                      const userDoc = await getDoc(doc(db, 'users', messageData.user));
                      const userName = userDoc.exists() ? userDoc.data().name : 'Unknown User';
                      return {
                        id: messageDoc.id,
                        userName,
                        messageText: messageData.message,
                      };
                    })
                  );
                  setChatMessages(messages);
                });

                // Fetch software development tasks
                const softwareTasksRef = collection(db, 'teams', userData.teamId, 'softwareTasks');
                const unsubscribeSoftwareTasks = onSnapshot(softwareTasksRef, (snapshot) => {
                  const tasksList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  setSoftwareTasks(tasksList);
                });

                // Fetch business/marketing tasks
                const businessTasksRef = collection(db, 'teams', userData.teamId, 'businessTasks');
                const unsubscribeBusinessTasks = onSnapshot(businessTasksRef, (snapshot) => {
                  const tasksList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  setBusinessTasks(tasksList);
                });

                // Fetch notifications
                const notificationsRef = collection(db, 'teams', userData.teamId, 'notifications');
                const unsubscribeNotifications = onSnapshot(notificationsRef, (snapshot) => {
                  const notificationsList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  setNotifications(notificationsList);
                });

                return () => {
                  unsubscribe();
                  unsubscribeSoftwareTasks();
                  unsubscribeBusinessTasks();
                  unsubscribeNotifications();
                };
              }
            }
          }
        } catch (error) {
          console.error('Error fetching team data:', error);
        }
      }
    };

    fetchTeamData();
  }, [currentUser]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      return; // Do not send empty messages
    }

    if (!teamData || !teamData.teamId) {
      console.error('Team data is missing or incomplete.');
      return;
    }

    try {
      const messagesRef = collection(db, 'teams', teamData.teamId, 'messages');

      await addDoc(messagesRef, {
        user: currentUser.uid,
        message: newMessage,
        timestamp: serverTimestamp(),
      });

      // Clear the input after sending the message
      setNewMessage('');

    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send the message. Please try again.');
    }
  };

  const handleAddTask = async (e, taskType) => {
    e.preventDefault();

    const task = taskType === 'software' ? newSoftwareTask : newBusinessTask;
    const link = taskType === 'software' ? newSoftwareTaskLink : newBusinessTaskLink;

    if (!task.trim()) {
      return; // Do not add empty tasks
    }

    if (!teamData || !teamData.teamId) {
      console.error('Team data is missing or incomplete.');
      return;
    }

    try {
      const tasksRef = collection(db, 'teams', teamData.teamId, taskType === 'software' ? 'softwareTasks' : 'businessTasks');

      await addDoc(tasksRef, {
        task,
        link,
        createdBy: currentUser.uid,
        createdAt: serverTimestamp(),
        completed: false,  // Add a field to indicate task completion
      });

      if (taskType === 'software') {
        setNewSoftwareTask('');
        setNewSoftwareTaskLink('');
      } else {
        setNewBusinessTask('');
        setNewBusinessTaskLink('');
      }

    } catch (error) {
      console.error('Error adding task:', error);
      alert('Failed to add the task. Please try again.');
    }
  };

  const openModal = (taskId, taskType) => {
    setCurrentTaskId(taskId);
    setCurrentTaskType(taskType);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentTaskId(null);
    setCurrentTaskType('');
    setCompletionLink('');
    setFile(null);
  };

  const handleMarkAsDone = async () => {
    const taskRef = doc(db, 'teams', teamData.teamId, currentTaskType === 'software' ? 'softwareTasks' : 'businessTasks', currentTaskId);
  
    try {
      let doneLink = completionLink;
  
      // If a file is uploaded, get the download URL
      if (file) {
        const storageRef = ref(storage, `tasks/${currentTaskId}/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        doneLink = await getDownloadURL(snapshot.ref);
      }
  
      // Mark the task as done even if no file or link is provided
      await updateDoc(taskRef, {
        completed: true,
        doneLink: doneLink || '', // If no link, store an empty string
        completedAt: serverTimestamp(),
      });
  
      // Send a notification for the task completion
      const notificationsRef = collection(db, 'teams', teamData.teamId, 'notifications');
      await addDoc(notificationsRef, {
        message: `Task completed: ${currentTaskType === 'software' ? 'Software Development' : 'Business & Marketing'}`,
        link: doneLink || null, // Set to null if no link
        timestamp: serverTimestamp(),
      });
  
      // Close the modal after marking the task as done
      closeModal();
  
    } catch (error) {
      console.error('Error marking task as done:', error);
      alert('Failed to mark the task as done. Please try again.');
    }
  };
  

  const handleMemberAdded = async (newMemberName) => {
    if (!teamData || !teamData.teamId) return;

    try {
      // Send a notification when a new member is added
      const notificationsRef = collection(db, 'teams', teamData.teamId, 'notifications');
      await addDoc(notificationsRef, {
        message: `New member added to the team: ${newMemberName}`,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error sending new member notification:', error);
    }
  };

  const handleGithubUpdate = async () => {
    if (!teamData || !teamData.teamId) return;
    setIsUpdating(true);

    try {
      const teamRef = doc(db, 'teams', teamData.teamId);
      await updateDoc(teamRef, {
        githubLink: githubLink,
      });
      alert('GitHub link updated successfully!');
    } catch (error) {
      console.error('Error updating GitHub link:', error);
      alert('Failed to update GitHub link. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  if (!teamData) {
    return (
      <>
        <GlobalStyle />
        <Navbar />
        <VibrantContainer>
          <VibrantText>Your Team is Conjuring...</VibrantText>
          <VibrantSubText>Please wait while we gather your team data.</VibrantSubText>
        </VibrantContainer>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Navbar />
      <Container>
        <Header>Team : {teamData.teamName}</Header>

        <Section>
          <SubHeader>Team Overview</SubHeader>
          <SubLabel>Project Focus: {teamData.projectFocus}</SubLabel>
          <SubLabel>Team Members:</SubLabel>
          <TeamList>
            {teamData.members.map((member, index) => (
              <TeamMember key={index}>
                <MemberName>{member.name}</MemberName>
                <MemberRole>{member.role}</MemberRole>
              </TeamMember>
            ))}
          </TeamList>
        </Section>

        <Section>
          <SubHeader>Team Communication</SubHeader>
          <ChatContainer>
            {chatMessages.map((msg, index) => (
              <ChatMessage key={index}>
                <strong>{msg.userName}:</strong> {msg.messageText}
              </ChatMessage>
            ))}
            <ChatInput
              type="text"
              placeholder="Type a message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <Button onClick={handleSendMessage}>Send</Button>
          </ChatContainer>
        </Section>

        <Section>
          <SubHeader>Team Project Tracking</SubHeader>

          <TaskCategory>
            <TaskCategoryHeader>Software Development Tasks</TaskCategoryHeader>
            <TaskList>
              {softwareTasks
                .sort((a, b) => {
                  const aTime = a.createdAt ? a.createdAt.seconds : 0;
                  const bTime = b.createdAt ? b.createdAt.seconds : 0;
                  return aTime - bTime; // Sort by oldest first
                })
                .map((task, index) => (
                  <TaskItem key={index}>
                    <TaskDetails>
                      {task.task}
                      <TaskDate>
                        Created: {task.createdAt ? new Date(task.createdAt.seconds * 1000).toLocaleString() : 'Unknown'}
                      </TaskDate>
                      {task.completed && (
                        <TaskLink href={task.doneLink} target="_blank" rel="noopener noreferrer">
                          [Completed Task]
                        </TaskLink>
                      )}
                    </TaskDetails>
                    {!task.completed && (
                      <TaskActions>
                        <Button onClick={() => openModal(task.id, 'software')}>Mark as Done</Button>
                      </TaskActions>
                    )}
                  </TaskItem>
                ))}
            </TaskList>
            <form onSubmit={(e) => handleAddTask(e, 'software')} style={{ marginTop: '20px' }}>
              <input
                type="text"
                placeholder="New Software Task"
                value={newSoftwareTask}
                onChange={(e) => setNewSoftwareTask(e.target.value)}
                required
                style={{
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  marginBottom: '10px',
                  width: '100%',
                }}
              />
              <Button type="submit">Add Software Task</Button>
            </form>
          </TaskCategory>

          <TaskCategory>
            <TaskCategoryHeader>Business & Marketing Tasks</TaskCategoryHeader>
            <TaskList>
              {businessTasks
                .sort((a, b) => {
                  const aTime = a.createdAt ? a.createdAt.seconds : 0;
                  const bTime = b.createdAt ? b.createdAt.seconds : 0;
                  return aTime - bTime; // Sort by oldest first
                })
                .map((task, index) => (
                  <TaskItem key={index}>
                    <TaskDetails>
                      {task.task}
                      <TaskDate>
                        Created: {task.createdAt ? new Date(task.createdAt.seconds * 1000).toLocaleString() : 'Unknown'}
                      </TaskDate>
                      {task.completed && (
                        <TaskLink href={task.doneLink} target="_blank" rel="noopener noreferrer">
                          [Completed Task]
                        </TaskLink>
                      )}
                    </TaskDetails>
                    {!task.completed && (
                      <TaskActions>
                        <Button onClick={() => openModal(task.id, 'business')}>Mark as Done</Button>
                      </TaskActions>
                    )}
                  </TaskItem>
                ))}
            </TaskList>
            <form onSubmit={(e) => handleAddTask(e, 'business')} style={{ marginTop: '20px' }}>
              <input
                type="text"
                placeholder="New Business Task"
                value={newBusinessTask}
                onChange={(e) => setNewBusinessTask(e.target.value)}
                required
                style={{
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  marginBottom: '10px',
                  width: '100%',
                }}
              />
              <Button type="submit">Add Business Task</Button>
            </form>
          </TaskCategory>
        </Section>

        <Section>
          <SubHeader>Notifications</SubHeader>
          {notifications.map((notification, index) => (
            <Notification key={index}>{notification.message}</Notification>
          ))}
        </Section>
        {/* GitHub Link Section */}
        <GithubSection>
          <GithubLabel>Team GitHub Repository</GithubLabel>
          <GithubInput
            type="url"
            placeholder="Enter GitHub repo link"
            value={githubLink}
            onChange={(e) => setGithubLink(e.target.value)}
          />
          <GithubButton onClick={handleGithubUpdate} disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update GitHub Link'}
          </GithubButton>

          {/* Display the GitHub link as a button if it's available */}
          {teamData.githubLink && (
            <div style={{ marginTop: '20px' }}>
              <GithubLink href={teamData.githubLink} target="_blank" rel="noopener noreferrer">
                <FaGithub size={20} />
                View GitHub Repo
              </GithubLink>
            </div>
          )}
        </GithubSection>
      </Container>

      {/* Modal for Mark as Done */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Mark Task as Done"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '30px',
            borderRadius: '12px',
            border: '1px solid #e0e0e0',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            maxWidth: '450px',
            width: '100%',
          },
        }}
      >
        <ModalContent>
          <ModalHeader>Mark Task as Done</ModalHeader>
          <Text>Optional: You may provide a link or upload a file</Text>

          <ModalInput
            type="url"
            placeholder="Link to completed task (optional)"
            value={completionLink}
            onChange={(e) => setCompletionLink(e.target.value)}
          />
          <ModalInput
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            style={{ border: '1px solid #ccc', padding: '8px' }}
          />

          <ModalActions>
            <ModalButton onClick={handleMarkAsDone}>Submit</ModalButton>
            <ModalButton onClick={closeModal} style={{ backgroundColor: '#ff4d4d' }}>Cancel</ModalButton>
          </ModalActions>
        </ModalContent>
      </Modal>

    </>
  );
};

export default TeamsScreen;