import React, { useState } from 'react';
import styled from 'styled-components';
import GlobalStyle from '../GlobalStyles'; 
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

// Styled components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Section = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
  align-items: center;
  justify-content: space-between; /* Balanced spacing */
  background-color: ${({ bgColor }) => bgColor || '#ffffff'};
  color: ${({ color }) => color || '#333'};
  padding: 100px 5%;
  width: 100%;
  min-height: 100vh; /* Full-screen sections */
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 50px 10px;
  }
`;

const Content = styled.div`
  width: 50%;
  padding: 0 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }
`;

const Header = styled.h1`
  font-size: 42px;
  color: #1a1a1a;
  margin-bottom: 20px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 15px;
  }
`;

const Text = styled.p`
  font-size: 20px;
  margin-bottom: 25px;
  line-height: 1.7;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 1.7;
  color: #333;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ListItem = styled.li`
  margin-bottom: 15px;
`;

const Button = styled.button`
  background-color: #28a745; /* Revert to original green color */
  color: white;
  border: none;
  padding: 20px 50px; /* Larger padding */
  font-size: 22px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-transform: uppercase; /* All caps for emphasis */
  &:hover {
    background-color: #218838;
    transform: translateY(-3px); /* Adds a subtle lift effect */
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 18px;
    font-size: 18px;
  }
`;

const Image = styled.img`
  width: 45%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const GreenImage = styled.img`
  width: 80%;
  height: 400px;
  border-radius: 10px;
  background-color: #4CAF50;
  object-fit: cover;
  margin-top: 20px;
`;

// Dedicated Full-Screen CTA Section
const FullScreenCTASection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1a73e8, #4caf50); /* Gradient background */
  color: white;
  width: 100%;
  height: 100vh; /* Full-screen height */
  text-align: center;
  padding: 0;
`;

const FullScreenCTAHeader = styled.h2`
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const FullScreenCTAText = styled.p`
  font-size: 28px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const FullScreenCTAButton = styled(Button)`
  background-color: #000; /* Black color for attention */
  &:hover {
    background-color: #444;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  text-align: left;
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }
`;

// Main Landing Page Component
const LandingPage = () => {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <GlobalStyle /> 
      <PageContainer>

        {/* Full-Screen CTA Section */}
        <FullScreenCTASection>
          <FullScreenCTAHeader>Ready to Start Building Your Future?</FullScreenCTAHeader>
          <FullScreenCTAText>Join GradJump today and be part of a team that innovates, builds, and succeeds together.</FullScreenCTAText>
          <FullScreenCTAButton onClick={() => navigate('/apply')}>Get Started Now</FullScreenCTAButton>
        </FullScreenCTASection>

        {/* Main Sections */}
        <Section bgColor="#e8f5e9">
          <Content>
            <Header>Turn Your Ideas into Reality</Header>
            <List>
              <ListItem>Join forces with fellow graduates to launch your own startup.</ListItem>
              <ListItem>Build, Innovate, Succeed: Collaborate on real projects that enhance your resume and credibility.</ListItem>
            </List>
            <Button onClick={() => navigate('/apply')}>Join Now for Early Access</Button>
          </Content>
          <Image src="/images/image4.png" alt="Team Working Together" />
        </Section>

        <Section bgColor="#ffffff">
          <Image src="/images/image2.png" alt="Discussion Among Team" />
          <Content>
            <Header>Unlock Your Potential</Header>
            <List>
              <ListItem>Elevate your career with hands-on experience.</ListItem>
              <ListItem>Our platform forms small, curated teams of graduates to launch projects from the ground up.</ListItem>
              <ListItem>Combine your skills in tech, marketing, and finance to create impactful startups, share profits, and gain valuable experience.</ListItem>
              <ListItem>Build life-long connections and manage your projects with our resources, with guidance available as needed.</ListItem>
            </List>
            <Button onClick={openModal}>Learn More</Button>
          </Content>
        </Section>

        <Section bgColor="#e8f5e9">
          <Content>
            <Header>Why Join Us?</Header>
            <List>
              <ListItem>Founder Circles: Join curated groups to launch impactful projects from scratch.</ListItem>
              <ListItem>Real-World Experience: Gain hands-on skills in tech, marketing, and finance.</ListItem>
              <ListItem>Shared Success: Collaborate, share profits, and build life-long connections.</ListItem>
              <ListItem>Supportive Resources: Access tools and guidance to manage your projects with confidence.</ListItem>
            </List>
            <Button onClick={() => navigate('/apply')}>Apply Now</Button>
          </Content>
          <Image src="/images/image3.png" alt="Team Collaboration" />
        </Section>

      </PageContainer>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Learn More"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '40px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            maxWidth: '600px',
            width: '100%',
          },
        }}
      >
        <ModalContent>
          <ModalHeader>Why Join Us?</ModalHeader>
          <ModalText>
            <strong>Build Your Resume:</strong> Gain hands-on experience in real-world projects that will enhance your portfolio and make you stand out to potential employers.
          </ModalText>
          <ModalText>
            <strong>Get Hired:</strong> Companies are looking for candidates with practical experience. Showcase your skills and get hired based on the projects you've successfully completed.
          </ModalText>
          <ModalText>
            <strong>Start Your Own Business:</strong> Collaborate with like-minded individuals to build a startup from the ground up. Leverage our resources and guidance to turn your ideas into reality.
          </ModalText>
          <ModalActions>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalActions>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LandingPage;