import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase'; // Ensure Firebase is properly configured and imported
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // For image uploads
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Firestore for blog posts
import GlobalStyle from '../GlobalStyles';
import Navbar from './Navbar';
import ReactQuill from 'react-quill'; // Rich text editor
import 'react-quill/dist/quill.snow.css'; // Styles for the editor

// Styled Components
const DashboardContainer = styled.div`
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BlogForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FileInput = styled.input`
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const BlogDashboard = ({ posts, setPosts }) => {
  const [title, setTitle] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [content, setContent] = useState(''); // Rich text content
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  // Function to handle image upload to Firebase Storage
  const handleImageUpload = async () => {
    if (!imageFile) return null; // If no image is provided, return null
    const storageRef = ref(storage, `blog-images/${imageFile.name}`);
    const snapshot = await uploadBytes(storageRef, imageFile); // Upload image to Firebase storage
    const imageUrl = await getDownloadURL(snapshot.ref); // Get the URL of the uploaded image
    return imageUrl;
  };

  // Function to handle form submission and save post to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true); // Show loading state while uploading

    try {
      const imageUrl = await handleImageUpload(); // Upload the image and get the URL
      const newPost = {
        title,
        image: imageUrl || '', // Set image URL if uploaded, else leave blank
        content, // Save rich text content
        slug: title.toLowerCase().replace(/ /g, '-'), // Create slug from title
        createdAt: serverTimestamp(), // Use Firebase timestamp
      };

      // Save blog post to Firestore
      await addDoc(collection(db, 'blogs'), newPost);
      setPosts([...posts, newPost]); // Update state with new post

      // Reset form fields after submission
      setTitle('');
      setImageFile(null);
      setContent('');
      setIsUploading(false);

      // Navigate to the blog listing page
      navigate('/blogs');
    } catch (error) {
      console.error('Error uploading blog post:', error);
      setIsUploading(false); // Stop loading state
    }
  };

  return (
    <>
      <GlobalStyle />
      <Navbar />
      <DashboardContainer>
        <h1>Create New Blog Post</h1>
        <BlogForm onSubmit={handleSubmit}>
          {/* Title Input */}
          <Input
            type="text"
            placeholder="Blog Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          {/* Image Upload */}
          <FileInput
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
          {/* Rich Text Editor */}
          <ReactQuill
            value={content}
            onChange={setContent}
            theme="snow"
            placeholder="Write your blog content here..."
          />
          {/* Submit Button */}
          <Button type="submit" disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Create Post'}
          </Button>
        </BlogForm>
      </DashboardContainer>
    </>
  );
};

export default BlogDashboard;
