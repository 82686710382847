// src/screens/HomeScreen.js
import React from 'react';
import Navbar from '../components/Navbar';
import LandingPage from '../components/LandingPage';
import Footer from '../components/Footer';

const HomeScreen = () => {
  return (
    <>
      <Navbar />
      <LandingPage />
      <Footer />
    </>
  );
};

export default HomeScreen;
