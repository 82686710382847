import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import styled from 'styled-components';
import { AuthContext } from '../context/AuthContext';
import Navbar from '../components/Navbar'; 

const DashboardContainer = styled.div`
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f7f7f7;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
`;

const ApplicationList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ApplicationItem = styled.li`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ApplicationDetail = styled.div`
  margin-bottom: 10px;
`;

const ApplicationLink = styled.a`
  color: #4CAF50;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ApplicationsDashboard = () => {
  const { currentUser } = useContext(AuthContext);

  // Ensuring hooks are not called conditionally
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplications = async () => {
      const applicationsCollection = collection(db, 'applications');
      const applicationsSnapshot = await getDocs(applicationsCollection);
      const applicationsList = applicationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setApplications(applicationsList);
    };

    fetchApplications();
  }, []);

  // Conditionally rendering after hooks have been called
  if (!currentUser || currentUser.role !== 'Admin') {
    return <div>You do not have permission to view this page.</div>;
  }

  return (
    <>
      <Navbar />
    <DashboardContainer>
      <h1>Applications Dashboard</h1>
      <ApplicationList>
        {applications.map((application) => (
          <ApplicationItem key={application.id}>
            <ApplicationDetail><strong>Name:</strong> {application.name}</ApplicationDetail>
            <ApplicationDetail><strong>Email:</strong> {application.email}</ApplicationDetail>
            <ApplicationDetail><strong>Skills:</strong> {application.skills.join(', ')}</ApplicationDetail>
            <ApplicationDetail><strong>Motivation:</strong> {application.motivation}</ApplicationDetail>
            {application.resumeUrl && (
              <ApplicationDetail>
                <strong>Resume:</strong> <ApplicationLink href={application.resumeUrl} target="_blank">View Resume</ApplicationLink>
              </ApplicationDetail>
            )}
            {application.transcriptUrl && (
              <ApplicationDetail>
                <strong>Transcript:</strong> <ApplicationLink href={application.transcriptUrl} target="_blank">View Transcript</ApplicationLink>
              </ApplicationDetail>
            )}
            <ApplicationDetail><strong>Submitted At:</strong> {new Date(application.submittedAt.seconds * 1000).toLocaleString()}</ApplicationDetail>
          </ApplicationItem>
        ))}
      </ApplicationList>
    </DashboardContainer>
    </>
  );
};

export default ApplicationsDashboard;
