import React from 'react';
import styled from 'styled-components';

// Styled Components for Footer
const FooterContainer = styled.footer`
  background-color: #f1f1f1;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 30px;
`;

const FooterLink = styled.a`
  color: #333;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;

  &:hover {
    color: #4CAF50;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const FooterText = styled.p`
  font-size: 14px;
  color: #666;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinks>
          {/* Link to the Terms of Service PDF stored in public/images */}
          <FooterLink href="/images/GradjumpTOS.pdf" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </FooterLink>
        </FooterLinks>
        <FooterText>&copy; {new Date().getFullYear()} GradJump. All Rights Reserved.</FooterText>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
